<template>
  <main>
    <h1>Error 404 # Page not found :(</h1>
  </main>
</template>

<script>
export default {
  name: "Error"
}
</script>

<style scoped>
  main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
</style>
